const OPEN_MODALS_ATTR = 'fv_open_modals'
const body = $('body')

const OpenModals = {
    get() {
        if (typeof (body.data(OPEN_MODALS_ATTR)) == 'undefined') {
            body.data(OPEN_MODALS_ATTR, [])
        }
        return body.data(OPEN_MODALS_ATTR)
    },
    push(modalId) {
        let currentShownModals = this.get()
        currentShownModals.push(modalId)
        body.data(OPEN_MODALS_ATTR, currentShownModals)
    },
    remove(id) {
        const currentOpenModals = this.get()

        const targetIndex = currentOpenModals.indexOf(id)
        if (targetIndex !== undefined) {
            currentOpenModals.splice(targetIndex, 1)
        }
        body.data(OPEN_MODALS_ATTR, currentOpenModals);
    },
    getLast() {
        return this.get().lastItem
    }
}


/**
 * Baseado em https://stackoverflow.com/a/21816777/4013296
 * @param selector
 */
function addCustomModalHandlersTo(selector) {

    const modal = $(selector)

    if (modal.data('was-initialized') !== true) {
        modal.data('was-initialized', true)

        const BASE_INDEX = 1040
        modal.on('hidden.bs.modal', function (event) {
            const $this = $(this)
            const currentModalId = $(this).attr('id')
            $this.removeClass('fv-modal-stack')
            OpenModals.remove(currentModalId)

            const last = OpenModals.getLast()
            if (last) {
                $(`#${last}`).trigger('focus')
            }
        })

        modal.on('shown.bs.modal', function (event) {
            // if the z-index of this modal has been set, ignore.
            if ($(this).hasClass('fv-modal-stack')) {
                return
            }

            const
                currentModalId = $(this).attr('id'),
                currentShownModals = OpenModals.get(),
                currentShownModalsCount = currentShownModals.length,
                newIndex = BASE_INDEX + (10 * currentShownModalsCount)


            $(this)
                .addClass('fv-modal-stack')
                .css('z-index', newIndex)

            OpenModals.push(currentModalId)

            $('.modal-backdrop').not('.fv-modal-stack')
                .css('z-index', newIndex - 1)
                .addClass('fv-modal-stack')

        })
    }
}


class ModalObject {
    id
    el

    constructor(id) {
        this.id = id
        this.el = document.getElementById(id)
    }

    disableClose() {
        this.reconfigureInstance({
            'backdrop': 'static',
            'keyboard': false
        })

        this.getCloseButton().prop('disabled', true)
    }

    enableClose() {
        this.reconfigureInstance({
            'backdrop': true,
            'keyboard': true
        })

        this.getCloseButton().removeAttr('disabled')
    }

    show() {
        $(this.el).modal({
            show: true,
            focus: true
        })
    }

    hide() {
        $(this.el).modal('hide')
    }

    getModalInstance() {
        return $(this.el).data('bs.modal')
    }

    reconfigureInstance(config) {
        const instance = this.getModalInstance()
        instance._config = instance._getConfig(config)
    }

    getCloseButton() {
        return $(this.el).find('.modal-content .modal-header button.close')
    }
}

const modal = {
    /**
     *
     * @param id
     * @returns {ModalObject}
     */
    get: (id) => {
        if (document.getElementById(id)) {
            addCustomModalHandlersTo('#' + id)
            return new ModalObject(id)
        } else {
            throw ('Erro ao chamar modal.get(): elemento com id ' + id + ' não encontrado')
        }
    },
    /**
     *
     * @param id
     * @returns {ModalObject}
     */
    create: (id) => {
        debugger
        const object = new ModalObject(id)
        addCustomModalHandlersTo('#' + id)
        return object
    },
    init(selector) {
        addCustomModalHandlersTo(selector)
    }
}


export default modal